import Vue from 'vue';
import Vuex from 'vuex';
//import Api from "@/services/api";

// IMPORT VUEX MODULES
import status from './modules/status'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        status: status
    },
    state: {

    },
    mutations: {
        
    },
    actions: {

    },
    getters: {

    }
});
export default store;