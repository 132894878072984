<template>
    <v-container fluid class="pa-0 d-flex fill-height"
        :style="{ backgroundImage: this.$store.state.status.colorGradient }">
        <!-- If the company plan is free, currentTrackerInfo will be an object, so the below v-row and it's content will render -->
        <v-row v-if="!Array.isArray(this.currentTrackerInfo)" class="justify-center ma-0">
            <v-col cols="12" md="11" class="mt-8 mb-4">
                <v-row justify="center">
                    <v-img class="justify-center" :max-width="182.85" transition="scale-transition" contain
                        :src="this.$store.state.status.statusIqCompanySettings ? this.$store.state.status.statusIqCompanySettings.logoUrl : '../assets/images/siqicon.svg'">
                    </v-img>
                </v-row>
            </v-col>
            <v-container>
                <v-row v-if="!hasVideo()">
                    <v-col :cols="this.vertical ? '3' : '12'" :sm="this.vertical ? '2' : '12'"
                        :md="this.vertical ? '2' : '12'"
                        :class="this.vertical ? 'stepper-container-vertical' : 'stepper-container-horizontal'">
                        <v-stepper flat style="background-color: transparent" non-linear dark v-model="currentStep"
                            :vertical="this.vertical" class="status-iq-stepper"
                            :class="this.$vuetify.breakpoint.smAndDown ? ' status-iq-stepper-small' : ''">
                            <!-- **************** -->
                            <!--    Horizontal    -->
                            <!-- **************** -->
                            <v-stepper-header v-if="!vertical">
                                <template v-for="(step, index) in steps">
                                    <v-stepper-step :step="index + 1" :key="index" class="d-block text-center">
                                        {{ step.title }}
                                        <!-- {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}} -->
                                        <!--limit the amount of characters on the title-->
                                    </v-stepper-step>
                                    <v-divider v-if="index < steps.length - 1" :key="index + 22"></v-divider>
                                </template>
                            </v-stepper-header>


                            <!-- **************** -->
                            <!--     Vertical     -->
                            <!-- **************** -->
                            <div v-if="vertical">
                                <template v-for="(step, index) in steps">
                                    <v-stepper-step :step="index + 1" :key="index">
                                        {{ step.title }}
                                        <!-- {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}} -->
                                        <!--limit the amount of characters on the title-->
                                    </v-stepper-step>
                                    <v-stepper-content v-if="index < steps.length - 1" :step="index + 1"
                                        :key="index + 22"></v-stepper-content>

                                </template>
                            </div>
                        </v-stepper>

                    </v-col>
                    <v-col :cols="this.vertical ? '9' : '12'" :sm="this.vertical ? '10' : '12'"
                        :md="this.vertical ? '9' : '12'" :class="this.vertical ? 'pl-0 pr-custom' : 'pt-0'">
                        <v-card elevation="20" rounded class="text-center d-flex flex-column justify-space-between"
                            style="height: 100%;">
                            <v-card-text>
                                <h1 class="text-center text-h3 pt-10">Hello {{ this.helloName ? this.helloName :
                                    "friend" }}!</h1>

                                <h6 class="secondary--text text-h6 mt-14">
                                    Your status is currently:
                                </h6>
                                <span class="text-h4">
                                    {{ this.currentStatus ? this.currentStatus[0].title : "" }}
                                </span>
                                <span class="text-h6 d-block pt-10 px-15" v-html="getStepText()"></span>

                            </v-card-text>
                            <v-card-text class="py-8">
                                <h6 class="secondary--text text-h6 px-15">
                                    If you have questions, please contact us at {{ this.phoneStr }} <a
                                        :href="'mailto:' + this.emailStr">{{
                                            this.emailStr }}</a> {{ this.emailSpaceStr }} <a target="_blank"
                                        :href="'//' + this.supportUrl">{{
                                            this.urlStr }}</a>
                                </h6>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <span>© {{ getCurrentYear() }} - Smart Ramp</span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>


                <!-- **************** -->
                <!--   Demo Section   -->
                <!-- **************** -->
                <v-row v-if="hasVideo()">
                    <v-col :cols="this.vertical ? '3' : '12'" :sm="this.vertical ? '2' : '12'"
                        :offset-md="this.vertical ? '0' : '1'" :md="this.vertical ? '2' : '10'"
                        :class="this.vertical ? 'stepper-container-vertical' : 'stepper-container-horizontal'">
                        <v-stepper flat style="background-color: transparent" non-linear dark v-model="currentStep"
                            :vertical="this.vertical" class="status-iq-stepper"
                            :class="this.$vuetify.breakpoint.smAndDown ? ' status-iq-stepper-small' : ''">
                            <!-- **************** -->
                            <!--    Horizontal    -->
                            <!-- **************** -->
                            <v-stepper-header v-if="!vertical">
                                <template v-for="(step, index) in steps">
                                    <v-stepper-step :step="index + 1" :key="index" class="d-block text-center">
                                        {{ step.title }}
                                        <!-- {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}} -->
                                        <!--limit the amount of characters on the title-->
                                    </v-stepper-step>
                                    <v-divider v-if="index < steps.length - 1" :key="index + 22"></v-divider>
                                </template>
                            </v-stepper-header>


                            <!-- **************** -->
                            <!--     Vertical     -->
                            <!-- **************** -->
                            <div v-if="vertical">
                                <template v-for="(step, index) in steps">
                                    <v-stepper-step :step="index + 1" :key="index">
                                        {{ step.title }}
                                        <!-- {{step.title.length > charLimit ? step.title.substring(0, charLimit) + '...': step.title}} -->
                                        <!--limit the amount of characters on the title-->
                                    </v-stepper-step>
                                    <v-stepper-content v-if="index < steps.length - 1" :step="index + 1"
                                        :key="index + 22"></v-stepper-content>

                                </template>
                            </div>
                        </v-stepper>

                    </v-col>
                    <v-col :cols="this.vertical ? '9' : '12'" :sm="this.vertical ? '10' : '12'"
                        :md="this.vertical ? '9' : '12'" :class="this.vertical ? 'pl-0 pr-custom' : 'pt-0'">
                        <v-card elevation="20" rounded class="text-center d-flex flex-column justify-space-between"
                            style="height: 100%;">
                            <v-card-title class="justify-center pt-14 pb-6 d-block">
                                <h1 class="text-center text-h3">Hello {{ this.helloName ? this.helloName : "friend" }}!
                                </h1>

                                <h6 class="secondary--text text-h6 mt-8">
                                    Your status is currently:
                                </h6>
                                <span class="text-h4">
                                    {{ this.currentStatus ? this.currentStatus[0].title : "" }}
                                </span>
                            </v-card-title>
                            <v-card-text>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <iframe width="100%" height="515" :src="this.getVideoUrl()"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen></iframe>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                            <v-card-text class="py-8">
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <h6 class="secondary--text text-h6">
                                            If you have questions, please contact us at {{ this.phoneStr }} <a
                                                :href="'mailto:' + this.emailStr">{{ this.emailStr }}</a> {{
                                                    this.emailSpaceStr }} <a target="_blank" :href="'//' + this.supportUrl">{{
                                                this.urlStr }}</a>
                                        </h6>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <span>© {{ getCurrentYear() }} - Smart Ramp</span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>

        <!-- If the company plan is any other than free, currentTrackerInfo will be an array of deals or tickets, so the below v-row and it's content will render -->
        <v-img v-if="Array.isArray(this.currentTrackerInfo)" style="position: fixed; top: 1rem; left: 1rem;"
            :max-width="80"
            :src="this.$store.state.status.statusIqCompanySettings ? this.$store.state.status.statusIqCompanySettings.logoUrl : '../assets/images/siqicon.svg'"></v-img>

        <v-row v-if="Array.isArray(this.currentTrackerInfo)" class="justify-center ma-0">
            <v-data-table :headers="this.currentTrackerInfo[0].type === 'TICKET' ? ticketTableHeaders : dealTableHeaders" style="width: 1280px; max-height: 800px;" :items="this.currentTrackerInfo" :items-per-page="20" class="elevation-1">
            </v-data-table>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

const ticketTableHeaders =  [
    {
        text: 'Name',
        align: 'start',
        value: 'name',
    },
    {
        text: "Status",
        align: 'start',
        value: 'stage',
    },
    {
        text: "Create date",
        align: 'start',
        value: 'createdate',
    },
    {
        text: "Description",
        align: 'start',
        value: 'description',
    }
]

const dealTableHeaders = [
    {
        text: 'Name',
        align: 'start',
        value: 'name',
    },
    {
        text: "Status",
        align: 'start',
        value: 'stage',
    },
    {
        text: "Create date",
        align: 'start',
        value: 'createdate',
    },
]

export default {
    data() {
        return {
            steps: [],
            currentStep: 5,
            vertical: false,
            charLimit: 15,

            //MAPPED VALUES
            helloName: null,
            currentStatus: null,
            supportPhone: null,
            supportEmail: null,
            supportUrl: null,

            //MESSAGE HELPERS
            phoneStr: "",
            emailStr: "",
            urlStr: "",
            emailSpaceStr: "",


            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },

            ticketTableHeaders,
            dealTableHeaders
        }
    },
    methods: {
        mapData() {
            // Checks if the current tracker info is a single object (free tier)
            if (this.currentTrackerInfo && !Array.isArray(this.currentTrackerInfo)) {
                const mappedTracker = this.currentTrackerInfo
                
                this.helloName = mappedTracker.contact.firstname;
                this.supportPhone = mappedTracker.contact.phoneNumber;
                this.supportEmail = mappedTracker.email;
                this.supportUrl = mappedTracker.supportUrl;

                this.buildPhoneStr();
                this.buildEmailSpaceStr();
                this.buildEmailStr();
                this.buildUrlStr();

                this.setActiveStep();
                this.setSteps();
            }
        },
        async setSteps() {
            this.steps = this.currentTrackerInfo ? this.currentTrackerInfo.steps : [];

            if (this.steps) {
                this.vertical = this.steps.length >= 5 ? true : false;
            }
        },
        setActiveStep() {
            if (this.currentTrackerInfo.currentStepId != null && this.currentTrackerInfo.currentStepId != undefined) {
                this.currentStep = this.currentTrackerInfo.steps.findIndex(stage => stage.position === this.currentTrackerInfo.currentStepId) + 1;
                this.currentStatus = this.currentTrackerInfo.steps.filter(stage => stage.position === this.currentTrackerInfo.currentStepId);
            }
        },
        buildPhoneStr() {
            this.phoneStr = this.supportPhone
                ? (this.supportPhone && this.supportEmail && this.supportUrl)
                    ? this.supportPhone + ", "
                    : (this.supportPhone && this.supportEmail || this.supportPhone && this.supportUrl)
                        ? this.supportPhone + " or "
                        : this.supportPhone
                : "";
        },
        buildEmailStr() {
            this.emailStr = this.supportEmail
                ? this.supportEmail
                : "";
        },
        buildEmailSpaceStr() {
            this.emailSpaceStr = (this.supportEmail && this.supportUrl
                ? " or "
                : "")
        },
        buildUrlStr() {
            this.urlStr = this.supportUrl ? this.supportUrl : "";
        },
        hasVideo() {
            // Hardcode to only show videos to Integrate IQ | Demo purposes
            // TODO remove this if we implement the videos for all our clients
            // return this.currentTrackerInfo.item4.companyId === "158b7073-5e61-4df7-8585-ea40298231f8";
            return false; // disable has video and replace with step texts
        },
        getVideoUrl() {
            // Hardcode to only show videos to Integrate IQ | Demo purposes
            // TODO remove this if we implement the videos for all our clients
            const video = ["https://www.youtube.com/embed/Vqx3AODz_xk", "https://www.youtube.com/embed/b_7llFCn21s", "https://www.youtube.com/embed/ntOhGEjAKkI"];

            const random = Math.floor(Math.random() * video.length);
            return video[random];

            // switch (statusId) {
            //     case "08db03d1-3712-4a2f-8939-831ce00e3627":
            //         return "https://www.youtube.com/embed/Vqx3AODz_xk";
            //     case "08db03d1-3719-4b96-8806-85cb57fe4ac1":
            //         return "https://www.youtube.com/embed/b_7llFCn21s";
            //     case "08db03d1-371c-49ab-87ac-24845527f841":
            //         return "https://www.youtube.com/embed/ntOhGEjAKkI";
            //     default:
            //         return "";
            // }
        },
        getStepText() {
            const status = this.currentStatus ? this.currentStatus[0].title : "";
            switch (status) {
                case "Welcome to Integrate IQ!":
                    return `Congratulations on choosing Integrate IQ for your integration project. Rest assured you are in good hands - we’ll take good care of you. The next step in the process is to send in payment for the setup fee. Courtney will send you a link for payment within the next business day. <br> We’re looking forward to getting your data where you need it!`;
                case "Initial Payment Complete":
                    return `Great news! You're on your way. We have received your initial payment. Next up, please provide your system credentials. We will need both front and back end admin access to each system. All access should be provisioned to <a target="_blank" href="mailto:partner@integrateiq.com">partner@integrateiq.com</a>. If you are a HubSpot user, please provide super admin access. If you have any questions, please reach out to Billing <a target="_blank" href="mailto:billing@integrateiq.com">billing@integrateiq.com</a>.`;
                case "Credentials Provided":
                    return "Fantastic! Great job on getting us your credentials. Our next step is to schedule a kickoff call to meet the team and discuss high level goals, the integration schedule and our process. Our team will be reaching out to you to get this on the calendar.";
                case "Kickoff Call":
                    return "We're moving right along! Your kickoff call is now scheduled. The goal of this call will be to make sure we're aligned on your goals and business case as well as outline the process for your integration to be completed.";
                case "Field Mapping Call":
                    return "Now we're getting down to the nitty gritty! We will get down to the specifics of field mapping and objects in our next call. Please make sure you have a clear picture of what you would like to get from this integration and what objects and fields are necessary to deliver that. Attendees for this meeting should be the business decisions makers as well as any technical resources.";
                case "Integration Build":
                    return "Now that we have the fields mapped, and we have the credentials we need, our team takes it from here. Please be on the lookout for questions from our team as we finalize the sync build. Once we are done with the initial build, we will reach out to complete testing alongside your team.";
                case "Additional Integration Build":
                    return "We are excited to build another integration for you and your company! As before, we will reach out with any questions, so please be on the lookout. Once the build is complete, we will perform initial tests and then invite you and your team to test alongside our team. As always, please feel free to reach out with any questions.";
                case "Integrate IQ Testing":
                    return "This is really starting to come together! We have the initial build complete and have already begun testing. We will be pushing records to verify that the trigger is working and the fields are being pushed as requested. The next step will be testing with you.";
                case "Joint Testing":
                    return "It's time for you to take center stage! Your first sync is built as mapped and our team has QA’d the work. Now is your turn to take a look and make sure the sync is built as you anticipated. While we can make adjustments at this stage, new requests will be made as a part of your continuity plan.";
                case "System Ready to Go Live":
                    return "Holy cow! Did you ever think you would see this day? Of course you did! Great job- your first integration is now built and tested!  To go live and for future syncs and enhancements, we just need your payment for the continuity subscription to get started. If we haven’t already done so, we’ll be sending you a link to set that up with just a few clicks. Once your sync is live, we will keep an eye on things for the next couple of weeks and then reach out for a wrap up meeting once we have all of your integrations complete. Amazing work!";
                case "Retrospective":
                    return "We have scheduled a retrospective between our teams. The purpose of this meeting is to validate that you have what you need, to reflect on the process, and to gather any feedback you might have to help us improve. We can't wait to connect!";
                case "Continuity":
                    return "Have questions? New requests? We are here to help! As part of your continuity subscription, your team can request new syncs and adjust the fields as your business needs as long as the changes are within the same objects and systems. We are proud to be your integration partner in the long-term!";
                case "Nurturing":
                    return "";
                case "Account Not Current":
                    return `Hi there! It seems we’ve run into an issue processing payment for your account. If we haven’t already, we will be reaching out with ways to address the issue on your account. If you have any questions, please email  <a target="_blank" href="mailto:support@integrateiq.com">Support@integrateiq.com</a>.`;
                default:
                    return "";
            }
        },

        getCompanySettings() {
            this.$store.dispatch('status/fetchStatusIqCompanySettings', this.$route.params.id)
        }
    },
    created() {
        this.mapData();

        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);

        this.handleResize();
    },
    beforeMount() {
        this.getCompanySettings()
    },

    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentTrackerInfo: 'status/getCurrentTrackerInfo',
        }),
    },
}
</script>

<style lang="scss">
v-stepper-header>.v-stepper__label {
    padding: 5px;
}

.pr-custom {
    padding-right: 7.5% !important
}

.text-container {
    white-space: nowrap;
}
</style>
