<template>
    <v-container fluid class="pa-0 d-flex fill-height" :style="{ backgroundImage: this.$store.state.status.colorGradient }">
        <v-row class="justify-center ma-0">
            <v-col cols="12" class="mt-8 mb-12" >
                <v-row justify="center">
                    <v-img
                        class="justify-center"
                        :max-width="182.85"  
                        transition="scale-transition"
                        contain
                        :src="this.$store.state.status.statusIqCompanySettings ? this.$store.state.status.statusIqCompanySettings.logoUrl : '../assets/images/siqicon.svg'">
                    </v-img>
                </v-row>
            </v-col>
            <v-container>
                <v-card rounded elevation="20" class="justify-center">
                    <v-row>
                        <v-col cols="12" offset="0"  md="8" offset-md="2" class="py-8 text-center">
                            <v-card-title style="line-height: 3rem;" class="justify-center">
                                <h1>Tracker not found</h1>
                            </v-card-title>
                            <v-card-text>
                                <v-col cols="12" class="pt-4">
                                    <h6 :class="'secondary--text text-h6'">We do not have an active account at that link.  Please check the link for any spelling errors.</h6>
                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <span>© {{getCurrentYear()}} - Smart Ramp </span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                   
                </v-card>
            </v-container>

        </v-row>
    </v-container>
</template>
<script>
export default {
    data () {
        return {
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    created() {
        
        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>