import status from '../store/modules/status';

export const hadUrl = async (to, from, next) => {
    let response = await status.actions.verifyTrackerUrl({}, to.params.id)

    // If the tracker url exist, continue with the route
    if (response) {
      if (response.data) {

        // Save tracker info in state variable
        status.state.currentTracker = response.data
        return next()
      }
    }
     else { // Otherwise, go to 404 page
      return next('/' + to.params.id + '/not-found')
    }  
  };

  export const hadOTP = (to, from, next) => {
    let hadOTP = status.state.currentTrackerInfo;

    // If the OTP was validated, continue with the route to status
    if (hadOTP) {
      return next()

    } else { // Otherwise, go to email verify
      return next('/' + to.params.id)
    }
  };