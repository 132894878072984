import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import vuetify from './plugins/vuetify'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/scss/statusiq.scss'

Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  customClass: { // Using this will required customClass to never  be used further in the definition
    container: 'v-application', //this patches the issue of the swal opening out of the vue vuetify v-application container so we emulate for styling purposes 
    confirmButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default primary ma-1',
    denyButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default error ma-1',
    cancelButton: 'v-btn v-btn--is-elevated v-btn--has-bg v-size--default warning ma-1',
    popup: 'bauhaus_light primary--text text--darken-5 elevation-24'
  }
})

import './mixins/globalMethods.js';
import '@/assets/css/styles.css'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
