import Api from "@/services/api";
import { trackerDataMapper } from "../../datamappers/trackerMapper";

export default {
    namespaced: true,
    state: {
        currentTracker: null,
        currentEmail: null,
        currentTrackerInfo: null,
        statusIqCompanySettings: null,
        colorGradient: 'linear-gradient(to bottom, #fa0393, #913a9b, #183370)'
    },
    actions: {
        saveTracker({commit},currentTracker){
            commit('SET_CURRENT_TRACKER',currentTracker);
        },
        saveEmail({commit},currentEmail){
            commit('SET_CURRENT_EMAIL',currentEmail);
        },
        saveTrackerInfo({commit},currentTrackerInfo){
            commit('SET_CURRENT_TRACKER_INFO',currentTrackerInfo);
        },
        // eslint-disable-next-line no-empty-pattern
        async verifyTrackerUrl({}, url) {
            let response = await Api().post(`/Web/SmartRamp/Status/GetTrackerCompany`, url)
                .catch((err) => {
                    console.log(err);
                });
            
            return response;
        },
        async verifyEmail({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },
        async verifyOTP({commit}, {otpInfo: otpInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify/${otpInfo.otpCode}`, otpInfo)
            .catch((err) => {
                console.log(err);
                return err.response;
            });

            commit('SET_CURRENT_TRACKER_INFO', trackerDataMapper.toDomain(response.data));
            
            return response;
        },
        async trackStatus({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },

        async validateEmail({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/VerifyEmail`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },
        async fetchStatusIqCompanySettings({ commit, dispatch }, url){
            const companySettings = await Api().get(`/Web/SmartRamp/Status/CompanySettings/${url}`).catch(err => {
                console.error(err.message);
                return false;
            });

            // parses the color config object because it's saved as a string on the database
            const parsedColorData = {
                ...companySettings.data,
                colorConfiguration: JSON.parse(companySettings.data.colorConfiguration)
            }
            
            commit("SET_STATUS_IQ_COMPANY_SETTINGS", parsedColorData);

            dispatch('getCustomGradient')
            
        },

        getCustomGradient({ commit }) {
            const colors = this.state.status.statusIqCompanySettings?.colorConfiguration;

            const primaryColor = colors?.primaryColor ?? '#fa0393'
            const secondaryColor = colors?.secondaryColor ?? '#913a9b'
            const tertiaryColor = colors?.tertiaryColor ?? '#183370'

            const gradient = `linear-gradient(to bottom, ${primaryColor}, ${secondaryColor}, ${tertiaryColor})`;

            commit("SET_COLOR_GRADIENT", gradient);
        }
    },
    mutations: {
        SET_CURRENT_TRACKER(state, currentTracker){
            state.currentTracker = currentTracker;
        },
        SET_CURRENT_EMAIL(state, currentEmail){
            state.currentEmail = currentEmail;
        },
        SET_COLOR_GRADIENT(state, gradient){
            state.colorGradient = gradient;
        },
        SET_CURRENT_TRACKER_INFO(state, currentTrackerInfo){
            state.currentTrackerInfo = currentTrackerInfo;
        },
        SET_STATUS_IQ_COMPANY_SETTINGS(state,companySettings){
            state.statusIqCompanySettings = companySettings;
        }
    },
    getters: {
        getCurrentTracker: state => { return state.currentTracker},
        getCurrentEmail: state => { return state.currentEmail},
        getCurrentTrackerInfo: state => { return state.currentTrackerInfo},
        getStatusIqCompanySettings: state => { return state.statusIqCompanySettings }     
    }
}
