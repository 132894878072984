import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from './colors';

import siqIcon from '../components/shared/IQIcons/products/siqIcon.vue';


Vue.use(Vuetify);

const iqicons = {
  siqIcon: {
    component: siqIcon,
  },
}

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: colors.miqfucsia, //f50886
            secondary: colors.miqslategray, //868e96
            accent: colors.miqspaceblue, //based on 212e60 (darken4) using 415A99 due to offset
    
            error: colors.miqfiercerose, //FF586B
            info: colors.miqlightblue, //1CBCD8
            success: colors.miqgreen, //0CC27E
            warning: colors.miqtangerine,  //FF8D60
    
            iq_slategray: colors.miqslategray,
            iq_lightslategray: colors.miqlightslategray,
            iq_desaturatedblue: colors.miqdesaturatedblue, //6686A6
            iq_lightblue: colors.miqlightblue, // based on CDE1F6 color (lighten4) using 6BA1F5 due to offset
            iq_darkblue: colors.miqspaceblue, // miq color used in accent
            iq_darkerblue: colors.miqblackblue, // 202332
            iq_aquagreen: colors.miqturquoise, //was 778da4
            iq_cyan: '#ed0c87', 
            
            iq_light: '#ffffff',
            iq_dark: '#000000',
            iq_concrete: colors.iq_concrete,
    
            iq_opaque: "ffffff80"
    
          },
          dark: {
            primary: '#F50886',
            secondary: '#868e96',
            accent: '#212e60',
            error: '#FF586B',
            info: '#1CBCD8',
            success: '#0CC27E',
            warning: '#FF8D60',     
    
            iq_lightblue: '#CDE1F6',
            iq_desaturatedblue: '#7c98b6',
            iq_darkblue: '#222d60',
            iq_darkerblue: '#202332',
            iq_aquagreen: '#778da4', 
            iq_cyan: '#ed0c87',
            iq_light: '#ffffff',
            iq_dark: '#000000',
    
          }
        },
        breakpoint: {
          mobileBreakpoint: 'sm',
          scrollBarWidth: 15,
        },
      },
      icons: {
        values: iqicons,
      }
});
