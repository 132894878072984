class TrackerDataMapper {
  constructor() {}

  // Trackers can either be an array or a single object, depending on the user account level
  toDomain(trackers) {
    // Check if it's an array, and if it's a deal
    if (Array.isArray(trackers) && trackers.some((tracker) => 'dealname' in tracker.item4)) {
      return trackers.map(this._convertDeal)
    }

    // Check if it's a single object, and if it's a deal
    if (!Array.isArray(trackers) && 'dealname' in trackers.item4) {
      return this._convertDeal(trackers)
    }

    // Check if it's an array, and if it's a ticket
    if (Array.isArray(trackers) && trackers.some((tracker) => 'content' in tracker.item4.properties)) {
      return trackers.map(this._convertTicket)
    }
    // Check if it's a single object, and if it's a ticket
    if (!Array.isArray(trackers) && 'content' in trackers.item4.properties) {
      return this._convertTicket(trackers)
    }
  }

  _convertDeal(tracker) {
    const currentStepId = tracker.item1
    const steps = tracker.item2
    const contact = tracker.item3
    const deal = tracker.item4

    return {
      objectId: deal.dealId,
      name: deal.dealname,
      description: 'No description',
      stage: deal.dealstage,
      supportUrl: deal.supportUrl,
      email: deal.email,
      createdate: new Date(+deal.createdate).toLocaleDateString(),
      type: 'DEAL',
      contact,
      steps,
      currentStepId
    }
  }

  _convertTicket(tracker) {
    const currentStepId = tracker.item1
    const steps = tracker.item2
    const contact = tracker.item3
    const ticket = tracker.item4

    return {
      objectId: ticket.properties.hs_object_id,
      name: ticket.properties.subject,
      description: ticket.properties.content,
      stage: ticket.properties.hs_pipeline_stage,
      createdate: new Date(ticket.properties.createdate).toLocaleDateString(),
      supportUrl: ticket.supportUrl,
      email: ticket.email,
      type: 'TICKET',
      contact,
      steps,
      currentStepId
    }
  }
}

export const trackerDataMapper = new TrackerDataMapper();