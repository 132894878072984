import Vue from 'vue';

Vue.mixin({
    data: function() {
        return {


        }
    },
    methods: {
        handleResize() { //LAYOUT TOOL: global handler
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        getCurrentYear(){
            return new Date().getFullYear();
        }
    }
  })

