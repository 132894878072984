<template>
    <v-container fluid class="pa-0 d-flex fill-height" :style="{ backgroundImage: this.$store.state.status.colorGradient }">
        <v-row class="justify-center ma-0">
            <v-col cols="12" class="mt-8 mb-12" >
                <v-row justify="center">
                    <v-img
                        class="justify-center"
                        :max-width="182.85"  
                        transition="scale-transition"
                        contain
                        :src="this.$store.state.status.statusIqCompanySettings ? this.$store.state.status.statusIqCompanySettings.logoUrl : '../assets/images/siqicon.svg'">
                    </v-img>
                </v-row>
            </v-col>
            <v-container>
                <v-card rounded elevation="20" class="justify-center">
                    <v-row>
                        <v-col cols="12" offset="0"  md="8" offset-md="2" class="py-8">
                            <v-card-title style="line-height: 3rem;">
                                <h1>Track your status with {{this.currentTracker.companyName}}</h1>
                            </v-card-title>
                            <v-card-text>
                                <v-form v-model="emailValid" id="emailForm" ref="emailForm" @submit.prevent> 
                                    <v-row class="">
                                        <v-col cols="12" class="pt-4">
                                            <h6 :class="'secondary--text text-h6'">Please enter your email to track your status </h6>
                                        </v-col>
                                        <v-col cols="12" class="d-inline-flex ">
                                            <v-text-field
                                                outlined
                                                clearable
                                                label="Type your email"
                                                height="20"
                                                v-model="email"
                                                @keydown="[validate]"
                                                :rules="emailRules"
                                                @keydown.enter="trackStatus"
                                            ></v-text-field>
                                            <v-btn
                                                
                                                x-large
                                                color="primary"
                                                @click="trackStatus" 
                                                :loading="loading"
                                                :class="!emailValid || this.email === null ? 'btn-disable-alt': ''"
                                                :disabled="!emailValid || this.email === null"
                                            >
                                                <span class="font-weight-bold">Track</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="my-0 justify-center">
                                    <v-col cols="10" class="d-flex justify-center">
                                        <span>© {{getCurrentYear()}} - Smart Ramp</span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                            <v-snackbar
                                class="snackbar-offset-mail"
                                v-model="snackbarInfo"
                                :color="snackbarColor"
                                :timeout="6000"
                                centered
                            >
                                <div class="snackbar-text">
                                    {{ text }} <br>
                                    {{ subtext }}
                                </div>
                                <template v-slot:action="{ attrs }">
                                    <v-btn
                                    text
                                    small
                                    fab
                                    v-bind="attrs"
                                    @click="snackbarInfo = false"
                                    >
                                    <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>

                            </v-snackbar>
                        </v-col>
                    </v-row>
                   
                </v-card>
            </v-container>

        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data () {
        return {
            email: null,
            emailValid: true,
            
            emailRules: [
                v => !!v || 'Required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            loading: false,

            snackbarInfo: false,
            snackbarColor: 'default',
            text: '',
            subtext: '',

            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    created() {
        this.emailValid = false;
        
        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.getTrackerIdUrl();
    },
    beforeMount() {
        this.getCompanySettings()
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentTracker: 'status/getCurrentTracker',
            currentEmail: 'status/getCurrentEmail'
        })
    },
    methods: {
        async getTrackerIdUrl(){
            this.email = this.currentEmail ? this.currentEmail : null;
        },

        getCompanySettings() {
            this.$store.dispatch('status/fetchStatusIqCompanySettings', this.$route.params.id)
        },

        validate(){
            this.$refs.trackerForm.validate()
        },
        resetValidation () {
            this.$refs.trackerForm.resetValidation()
        },

        async trackStatus(){
            this.loading = true;

            let validate = await this.$store.dispatch('status/validateEmail', 
            {
                "trackInfo": {
                    "Url": this.$route.params.id,
                    "Email": this.email
                }
            });

            if(validate.data){
                let response = await this.$store.dispatch('status/verifyEmail', 
                {
                    "trackInfo": {
                        "Url": this.$route.params.id,
                        "Email": this.email
                    }
                });
            
                if(response.status == 200){
                    this.loading = false;
                    this.$router.push('/' + this.$route.params.id + '/verify');
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error. Please try again.',
                    });
                    this.loading = false;
                }

            }
            else
            {
                this.showInfoMessage();
            }
        },
        showInfoMessage() {
            this.loading = false;
            this.snackbarColor = 'error'
            this.text = `Your contact information cannot be found with the data provided, please check and try again.`
            this.subtext = ``
            this.snackbarInfo = true
        }
    }
}
</script>

<style lang="scss">
    .snackbar-offset-mail{
        top: 213px;
    }
    .snackbar-text {
        font-size: 1.2rem;
    }
</style>
